import React from "react"
import styled from "styled-components"
import * as Icon from "react-feather"
import Button from "../ui/Button"
import useCart from "../../hooks/useCart"
import usePoster, { getPosterID } from "../../hooks/usePoster"
import { useTheme, Badge } from "@material-ui/core"

const Container = styled.div`
  margin-right: 10px;
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  color: ${p => p.theme.palette.text.primary};
`

const CartContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: relative;
`

// const Badge = styled.div`
//   width: 20px;
//   height: 20px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-size: 12px;
//   font-weight: bold;
//   border-radius: 50%;
//   position: absolute;
//   top: -10px;
//   right: -10px;
//   background-color: ${p => p.theme.palette.background.paper};
//   color: ${p => p.theme.palette.text.primary};
// `

const BuyButton = ({ onCheckout, state }) => {
  const theme = useTheme()
  const [poster, updatePoster] = usePoster()
  const { cartItems, addItem, updateItem, getCartItemByPoster } = useCart()

  const cartItem = getCartItemByPoster(poster)
  const hasChanged = cartItem
    ? getPosterID(poster) !== cartItem.posterData.id
    : false

  const handleCheckout = () => {
    if (cartItems.length) onCheckout()
  }

  const handleOrder = () => {
    if (!cartItem) {
      addItem(poster, 1)
    } else {
      const nextId = getPosterID(poster)
      updatePoster({ id: nextId })
      updateItem(cartItem.id, { ...poster, id: nextId })
    }
    // handleCheckout()
    // if (isInCart) {
    //   updateSelectedItem();
    // } else {
    //   addItem(state);
    // }
  }

  return (
    <Container>
      {!cartItem || hasChanged ? (
        <Button
          label={hasChanged ? "Update in cart" : "Add to cart"}
          labelStyle={{ fontFamily: "Gotham Pro", fontSize: 15 }}
          onClick={handleOrder}
          foregroundColor={state.foregroundColor}
          backgroundColor={state.backgroundColor}
          style={{ border: poster.framed ? "5px solid #000" : "none" }}
          primary
        />
      ) : (
        <span style={{ fontStyle: "italic" }}>In cart</span>
      )}
      {/* <Badge>
        <Icon.ShoppingCart />
      </Badge> */}
      <Badge
        badgeContent={cartItems.length}
        onClick={handleCheckout}
        color="primary"
        style={{
          marginLeft: 10,
          cursor: "pointer"
          // backgroundColor: theme.palette.background.default
        }}
      >
        <Icon.ShoppingCart />
      </Badge>
    </Container>
  )
}

export default BuyButton
