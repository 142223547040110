import React from "react";
import styled from "styled-components";
import { isEqual } from "lodash";
import MapBoxGL from "./MapBoxGL";
import { setDPI } from "../print/utils";

import { getMapStyle } from "../../utils/map-styles";

const MapContainer = styled.div``;

const MapImage = ({ width, height, src }) => {
  return (
    <div
      style={{
        width,
        height,
        zIndex: 10000,
        position: "absolute",
        pointerEvents: "none"
      }}
    >
      <img alt="map background" src={src} width={width} height={height} />
    </div>
  );
};

class Map extends React.Component {
  state = { image: null };

  constructor(props) {
    super(props);
    this.mapStyles = getMapStyle(this.props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const shouldUpdate =
      !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
    return shouldUpdate;
  }

  handleMapChanged = e => {
    this.props.onChange(e);
  };

  snapImage = map => {
    const image = map.getCanvas().toDataURL("image/png", 1.0);
    this.setState({ image });
    this.props.onImageSnapped(image);
  };

  componentWillReceiveProps(nextProps) {
    // TODO: only clear if we've got a map state change?
    if (this.state.image) this.setState({ image: null });
  }

  handleMapChangeStarted = e => {
    if (this.state.image) this.setState({ image: null });
  };

  handleMapRendered = map => {
    if (!map.isMoving()) this.snapImage(map);
  };

  render() {
    if (typeof window === "undefined") return null;

    const width = this.props.width;
    const height = this.props.height;
    const zoom = this.props.zoom;
    let mapScale = this.props.mapScale;
    if (this.props.scale > 0) {
      mapScale = mapScale - mapScale * this.props.scale;
    }
    const style = this.props.style ? this.props.style : getMapStyle(this.props);

    // TOOD: show loader here if rendering image
    const imageOverlay = this.state.image && this.props.overlayImage;

    return (
      <MapContainer>
        {imageOverlay ? (
          <MapImage
            id="map-image"
            src={this.state.image}
            width={width}
            height={height}
          />
        ) : null}

        {!imageOverlay ? (
          <MapBoxGL
            {...this.props}
            zoom={zoom}
            scale={mapScale}
            pitch={this.props.pitch}
            width={width}
            height={height}
            buffer={this.props.buffer}
            location={this.props.location}
            readonly={this.props.readonly}
            animateMove={this.props.animateMove}
            onRender={this.handleMapRendered}
            onChange={this.handleMapChanged}
            onChangeStarted={this.handleMapChangeStarted}
            renderDelay={this.props.renderDelay}
            transitionIn={this.props.transitionIn}
            style={style}
          />
        ) : null}
      </MapContainer>
    );
  }
}

Map.defaultProps = {
  onChange: () => {},
  onImageSnapped: () => {},
  // pitch: 50,
  pitch: 45,
  scale: 1,
  mapScale: 2,
  animateMove: false,
  overlayImage: true,
  renderDelay: 0,
  transitionIn: true
};

export default Map;
