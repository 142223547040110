import React from "react"
import styled, { keyframes } from "styled-components"
import MapCircle from "./MapCircle"
import PosterTextCircle from "./PosterTextCircle"
import PosterTextBlock from "./PosterTextBlock"
import PosterIcon from "./PosterIcon"
import color from "color"
import { createObjectHash } from "./utils"
import MapSquare from "./MapSquare"
import MapBG from "../ui/MapBG"

const PosterContainer = styled.div`
  user-select: none;
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  width: ${p => p.width}px;
  min-width: ${p => p.width}px;
  height: ${p => p.height}px;
  min-height: ${p => p.height}px;
  justify-content: center;
  align-items: center;
  transition: border, width, height, min-width, min-height, 0.2s;
  box-shadow: rgba(0, 0, 0, 0.19) 8px 12px 40px,
    rgba(0, 0, 0, 0.23) 8px 6px 12px;
`

const MapContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  width: ${p => p.radius * 2}px;
  height: ${p => p.radius * 2}px;
  /* background-color: ${p => p.backgroundColor}; */
  border-radius: "50%";
  /* overflow: hidden; */
  box-sizing: border-box;
`

const shine = keyframes`
  0% {
    background-position: 130%;    
  }
  100% {
    background-position: 30%;
  }
`

const PosterShine = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 150%;
  background-position: 30%;
  background-color: transparent;
  background-image: linear-gradient(
    -45deg,
    transparent 40%,
    ${p => p.shineColor} 50%,
    transparent 60%
  );
  background-size: 300% 100%;
  animation: ${shine} 0.33s ease-out;
  animation-delay: ${p => p.delay}s;
  z-index: 10000;
`

const Poster = props => {
  const {
    width,
    height,
    scaleDown,
    backgroundColor,
    foregroundColor,
    textTop,
    textBottom,
    chonky,
    scale,
    readonly,
    size,
    icon,
    gradient,
    textLayout,
    textStyle,
    transitionIn,
    style,
    framed,
    pitch,
    accentColor,
    onSelectTextBlock,
    print
  } = props

  const scaledWidth = width * scale * (size === "18x18" ? 1.05 : 1.2)
  const scaledPerc = 1 - width / scaledWidth

  const handleSelectText = position => {
    if (onSelectTextBlock) onSelectTextBlock(position)
  }

  let radius =
    textLayout === "circle"
      ? scaledWidth / 2.6
      : textTop && textTop.length && textBottom && textBottom.length
      ? scaledWidth / 3.2 // bottom and top text
      : scaledWidth / 3

  if (size === "18x24") radius *= 1.1
  if (size !== "18x18" && textLayout === "circle") radius *= 0.8

  const pinSize = 70 * scale

  const frameWidth = (scaledWidth / 18) * 0.75
  // Frame covers ~.5" of poster
  const showFrame = framed && !print
  const frameShrink = showFrame ? (scaledWidth / 18) * 0.5 : 0
  let posterStyles = {
    backgroundColor,
    boxSizing: showFrame ? "content-box" : "border-box",
    border: showFrame ? `${frameWidth}px solid #000` : `none`
    // left: showFrame && readonly ? -frameShrink : 0 // bigtime hack
  }

  if (scaleDown !== 1.0) {
    posterStyles.transform = `scale(${scaleDown})`
  }

  if (gradient)
    posterStyles = {
      ...posterStyles,
      backgroundImage: `linear-gradient(${gradient})`
    }

  const posterWidth = width * scale - frameShrink
  const posterHeight = height * scale - frameShrink

  // const lightColor = color(foregroundColor).isLight()
  //   ? foregroundColor
  //   : backgroundColor;

  // const shineColor = color(lightColor)
  //   .lighten(0.2)
  //   .alpha(0.3)
  //   .toString();
  const alpha = color(backgroundColor).isDark() ? 0.2 : 0.4
  const shineColor = `rgba(255,255,255, ${alpha})`
  const shineDelay = transitionIn ? 2.8 : 0.3
  const shineKey = createObjectHash({ size, backgroundColor, framed })

  const MapComponent = false ? MapSquare : MapCircle

  return (
    <PosterContainer
      width={posterWidth}
      height={posterHeight}
      style={{ ...posterStyles, ...style }}
    >
      {/* <MapBG
        backgroundColor={backgroundColor}
        width={posterWidth}
        height={posterHeight}
        posterState={props}
        location={props.location}
      /> */}
      {!print ? (
        <PosterShine
          shineColor={shineColor}
          delay={shineDelay}
          key={shineKey}
          // key={Math.random() * 1000}
          framed={framed}
        />
      ) : null}
      {textLayout === "block" ? (
        <PosterTextBlock
          scale={scale}
          size={size}
          width={radius * 2 * 0.9}
          textTop={textTop}
          textBottom={textBottom}
          textStyle={textStyle}
          position="top"
          foregroundColor={foregroundColor}
          onSelect={handleSelectText}
        />
      ) : null}

      <MapContainer
        backgroundColor={backgroundColor}
        radius={radius}
        width={scaledWidth}
      >
        <MapCircle
          {...props}
          transitionIn={transitionIn}
          style={undefined}
          radius={radius}
          scalePerc={scaledPerc}
          pitch={pitch !== undefined ? pitch : 45}
        >
          {icon ? (
            <PosterIcon
              icon={icon}
              size={pinSize}
              foregroundColor={foregroundColor}
              backgroundColor={backgroundColor}
              accentColor={accentColor}
              chonky={chonky}
            />
          ) : null}
        </MapCircle>
      </MapContainer>

      {textLayout === "block2" ? (
        <PosterTextBlock
          scale={scale}
          size={size}
          width={radius * 2 * 0.9}
          textTop={textTop}
          textBottom={textBottom}
          textStyle={textStyle}
          position="bottom"
          foregroundColor={foregroundColor}
          onSelect={handleSelectText}
        />
      ) : null}

      {textLayout === "circle" ? (
        <PosterTextCircle
          scale={scale}
          radius={radius}
          width={scaledWidth}
          textTop={textTop}
          textBottom={textBottom}
          textStyle={textStyle}
          foregroundColor={foregroundColor}
        />
      ) : null}
    </PosterContainer>
  )
}

Poster.defaultProps = {
  scale: 1,
  transitionIn: false,
  pitch: 45
}

export default Poster
