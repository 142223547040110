const basePoster = {
  size: "18x24",
  framed: true
}

const examples = [
  {
    location: {
      lng: -73.98341904632264,
      lat: 40.73070800491374
    },
    size: "18x24",
    textTop: "NYC",
    textBottom: "",
    showBorder: true,
    showSecondaryRoads: false,
    zoom: 11.74533881519157,
    backgroundColor: "#0C1622",
    foregroundColor: "#F9F6EF",
    icon: "",
    textLayout: "block",
    textStyle: "sans",
    showIntro: false,
    showPin: false,
    gradient: null,
    showControls: false,
    showBuy: false,
    banana: false,
    theme: ["#F9F6EF", "#0C1622"],
    accentColor: null,
    addressLocation: {
      lat: 40.7127753,
      lng: -74.0059728
    },
    framed: true,
    pitch: 55,
    invert: true,
    chonky: true
  },
  {
    location: {
      lng: -0.10422419107317182,
      lat: 51.50282727318594
    },
    size: "18x24",
    textTop: "London",
    textBottom: "51.507°N / -0.128°W",
    showBorder: true,
    showSecondaryRoads: true,
    zoom: 11.967423797613435,
    backgroundColor: "#373F51",
    foregroundColor: "#D8DBE2",
    iconURL: null,
    icon: "",
    textLayout: "block",
    textStyle: "sans"
  },
  {
    location: {
      lng: -122.43904293171295,
      lat: 37.66581393629734
    },
    size: "18x18",
    textTop: "SF",
    textBottom: "",
    showBorder: true,
    showSecondaryRoads: false,
    zoom: 10.858067234573713,
    backgroundColor: "#F3D060",
    foregroundColor: "#1F2020",
    iconURL: null,
    icon: "",
    textLayout: "block",
    textStyle: "sans"
  },
  {
    location: {
      lng: 2.3446264306907096,
      lat: 48.84315625597091
    },
    size: "18x24",
    textTop: "Paris",
    textBottom: "48.857°N / 2.352°E",
    showBorder: true,
    showSecondaryRoads: true,
    zoom: 11.14381871735778,
    backgroundColor: "#F9F6EF",
    foregroundColor: "#0C1622",
    iconURL: null,
    icon: "",
    textLayout: "block",
    textStyle: "script",
    showIntro: false,
    locationLabel: "Paris, France",
    lng: -0.12775829999998223,
    lat: 51.5073509
  },
  {
    location: {
      lng: -79.94395752875562,
      lat: 32.80312380358852
    },
    size: "18x18",
    textTop: "Charleston SC",
    textBottom: "32.776°N / -79.931°W",
    showBorder: true,
    showSecondaryRoads: true,
    zoom: 11.514615891471491,
    backgroundColor: "#54110e",
    foregroundColor: "#fff2a7",
    iconURL: null,
    icon: "",
    textLayout: "circle",
    textStyle: "serif",
    showIntro: false,
    locationLabel: "Charleston, SC, USA",
    lng: -79.93105120000001,
    lat: 32.7764749,
    label: "",
    title: "",
    showPin: false,
    gradient: null,
    bounds: {
      _sw: {
        lng: -122.51005430200807,
        lat: 37.5954927557796
      },
      _ne: {
        lng: -122.27106190666194,
        lat: 37.889505101764044
      }
    },
    showControls: false,
    showBuy: false,
    banana: false,
    theme: ["#54110e", "#fff2a7"],
    accentColor: null,
    addressLocation: {
      lat: 32.7764749,
      lng: -79.93105120000001
    },
    framed: true,
    pitch: 60,
    invert: false,
    chonky: true
  },
  {
    location: {
      lng: -53.63746490692927,
      lat: 11.05668169083279
    },
    size: "18x18",
    textTop: "Hello World",
    textBottom: "",
    showBorder: true,
    showSecondaryRoads: false,
    zoom: 1.8461244259339145,
    backgroundColor: "#25282A",
    foregroundColor: "#5EB0E5",
    iconURL: null,
    icon: "",
    textLayout: "circle",
    textStyle: "sans",
    showIntro: false,
    locationLabel: "Paris, France",
    lng: -0.12775829999998223,
    lat: 51.5073509,
    label: "",
    title: "",
    showPin: false,
    gradient: null,
    bounds: {
      _sw: {
        lng: -122.51005430200807,
        lat: 37.5954927557796
      },
      _ne: {
        lng: -122.27106190666194,
        lat: 37.889505101764044
      }
    },
    showControls: false,
    showBuy: false,
    banana: false,
    theme: ["#5EB0E5", "#25282A"],
    accentColor: null,
    addressLocation: {
      lat: 40.7127753,
      lng: -74.0059728
    },
    framed: true,
    pitch: 45,
    invert: true,
    chonky: true
  }
]

export const examplePosters = examples.map(data => ({ ...basePoster, ...data }))
