import React from "react"
import styled, { keyframes } from "styled-components"
import Color from "color"
import { useWindowSize } from "../../hooks/useWindowSize"

const pulse = keyframes`
  0% {
    opacity: .666;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: .666;
  }
`

const Bulb = styled.div`
  background-color: ${p => p.color};
  width: 10px;
  height: 28px;
  border-radius: 50%;
  position: absolute;
  left: ${p => p.left}px;
  transform: rotate(${p => p.rotation}deg);
  box-shadow: ${p => p.color} 0px 0px 20px;
  animation: ${pulse} ${p => p.delay}s ease-out infinite;
  animation-delay: ${p => p.delay}s;
`

const Strand = styled.div`
  width: 100%;
  position: absolute;
  top: -6px;
  left: 0px;
  z-index: 101;
`

const getStrand = width => {
  let strand = []
  const spacingMax = 32
  const spacingMin = 16
  const rotationRange = 36
  const maxDelay = 4
  let w = 0
  while (w < width) {
    const gap =
      spacingMin + Math.round(Math.random() * (spacingMax - spacingMin))
    const rotation =
      Math.floor(Math.random() * rotationRange) - rotationRange / 2
    const delay = Math.random() * maxDelay + maxDelay / 2
    strand.push({ x: w, rotation, delay })
    w += gap
  }
  return strand
}

const Lights = React.memo(({ width, foregroundColor, backgroundColor }) => {
  const strand = getStrand(width)
  const color =
    Color(foregroundColor).lightness() > Color(backgroundColor).lightness()
      ? foregroundColor
      : backgroundColor
  console.log(strand)
  return (
    <Strand>
      {strand.map(({ x, rotation, delay }, i) => {
        return (
          <Bulb
            key={i}
            left={x}
            rotation={rotation}
            color={color}
            delay={delay}
          />
        )
      })}
    </Strand>
  )
})

export default Lights
