import { useState, useEffect } from "react"

const getValueSafe = (key, defaultValue) => {
  if (typeof window !== `undefined`) {
    if (localStorage.getItem(key)) {
      try {
        return JSON.parse(localStorage.getItem(key))
      } catch (error) {
        return defaultValue
      }
    }
  }
  return defaultValue
}

const defaultReducer = value => value

const useStorage = (key, defaultValue, persistReducer = defaultReducer) => {
  const _enabled = typeof window !== `undefined`
  const [value, _setValue] = useState(getValueSafe(key, defaultValue))

  // save on change
  useEffect(() => {
    if (_enabled) {
      localStorage.setItem(key, JSON.stringify(persistReducer(value)))
    }
  }, [value])

  const setValue = data => _setValue(data)

  return [value, setValue]
}

export default useStorage
