import "mapbox-gl/dist/mapbox-gl.css"
import React from "react"
import styled from "styled-components"
import { isEqual, debounce, delay, defer } from "lodash"
import { isLocationDifferentEnough } from "./utils"

import { MAPBOX_TOKEN } from "../../constants"

import mapboxgl from "mapbox-gl"

mapboxgl.accessToken = MAPBOX_TOKEN

const MapContainer = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  canvas {
    transform-origin: 0 0;
    transform: ${p => `scale(${p.transformScale})`};
  }
  z-index: 1;
  width: ${p => `${p.width}px`};
  height: ${p => `${p.height}px`};
`

class MapBoxGL extends React.Component {
  state = { error: null }

  constructor(props) {
    super(props)
    this.mapID = `map-${Math.floor(Math.random() * 100)}`
  }

  componentWillUnmount = () => {
    if (this.map) this.map.remove()
  }

  componentWillReceiveProps(nextProps) {
    if (!this.map) return

    // This is not effecient!
    if (!isEqual(nextProps.style, this.props.style)) {
      this.map.setStyle(nextProps.style)
    }

    const loc = this.map.getCenter()
    const locationDidChange = isLocationDifferentEnough(loc, nextProps.location)

    const zoomDidChange =
      !this.map.isMoving() && nextProps.zoom !== this.props.zoom

    if (locationDidChange || zoomDidChange) {
      // this._didTransition = false
      const moveFunc = (nextProps.animateMove
        ? this.map.flyTo
        : this.map.jumpTo
      ).bind(this.map)
      const move = {
        center: nextProps.location,
        ...this.getTransitionalProps(nextProps.zoom)
      }
      moveFunc(move)
    }
  }

  onMoveEnd = e => {
    const nextProps = {
      location: this.map.getCenter(),
      zoom: this.map.getZoom()
    }
    const currentProps = {
      location: this.props.location,
      zoom: this.props.zoom
    }
    if (!isEqual(nextProps, currentProps) && this.props.onChange) {
      this.props.onChange(nextProps, this.map)
    }
  }

  onChangeStarted = e => {
    this.props.onChangeStarted()
  }

  getTransitionalProps = zoom => {
    const doTransition = this.props.transitionIn && !this._didTransition
    return {
      zoom: zoom - (doTransition ? 1 : 0),
      bearing: doTransition ? 9 : 0,
      pitch: doTransition ? this.props.pitch - 20 : this.props.pitch
    }
  }

  renderMap() {
    try {
      const mapProps = {
        container: this.mapID,
        renderWorldCopies: true,
        style: this.props.style,
        center: this.props.location,
        trackResize: false,
        preserveDrawingBuffer: this.props.preserveDrawingBuffer,
        pitchWithRotate: false,
        dragRotate: false,
        antialias: this.props.antialias,
        touchZoomRotate: true,
        interactive: !this.props.readonly,
        ...this.getTransitionalProps(this.props.zoom),
        ...this.props.mapProps
      }
      this.map = new mapboxgl.Map(mapProps)
      window.map = this.map
      this.map.on("moveend", this.onMoveEnd)
      this.map.on("movestart", this.onChangeStarted)
      this.map.on("zoomstart", this.onChangeStarted)
      this.map.on("load", this.handleOnLoad)
      this.map.on("render", debounce(this.handleMapRendered, 300))
      this.map.touchZoomRotate.disableRotation()
    } catch (error) {
      console.log("render map error!", error)
      this.setState({ error })
    }
  }

  handleOnLoad = () => {
    if (this.props.onLoad) this.props.onLoad(this.map)
  }

  handleMapRendered = () => {
    const shouldShowZoomTransition =
      this.props.transitionIn && !this.map.isMoving() && !this._didTransition
    if (shouldShowZoomTransition) {
      this.map.flyTo({
        zoom: this.props.zoom,
        speed: 0.2,
        bearing: 0,
        pitch: this.props.pitch,
        location: this.props.location
      })
      this._didTransition = true
      delay(() => this.props.onRender(this.map), 1000)
    } else {
      if (this.map.loaded()) delay(() => this.props.onRender(this.map), 500)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.map) return
    if (prevProps.width !== this.props.width) {
      this.map.resize()
    }
    if (prevProps.pitch !== this.props.pitch) {
      this.map.easeTo({ pitch: this.props.pitch })
    }
  }

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.width !== this.props.width ||
      nextProps.pitch !== this.props.pitch
    )
  }

  componentDidMount = () => {
    delay(() => this.renderMap(), this.props.renderDelay)
  }

  render() {
    if (this.state.error) return <div>Error initializing map!</div>

    const { width, height, scale } = this.props
    let transformScale = 1 / scale
    return (
      <MapContainer
        id={this.mapID}
        width={width * scale}
        height={height * scale}
        scale={scale}
        transformScale={transformScale}
      />
    )
  }
}

MapBoxGL.defaultProps = {
  mapProps: {},
  pitch: 45,
  zoom: 13,
  bearing: 0,
  buffer: 0,
  animateMove: false,
  scale: 3.3,
  enableBuildings: false,
  transitionIn: true,
  renderDelay: 0,
  antialias: true,
  preserveDrawingBuffer: true,
  onChange: e => {
    console.log(e)
  },
  onMapMoved: data => {},
  onRender: () => {}
}

export default MapBoxGL
