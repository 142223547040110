import React, { useState } from "react"
import "react-hint/css/index.css"
import ReactHintFactory from "react-hint"
import { navigate } from "gatsby"
import get from "lodash/get"
import isEqual from "lodash/isEqual"
import styled from "styled-components"
import withSizes from "react-sizes"
import Drawer from "./Drawer"
import Poster from "../poster/Poster"
import PosterForm from "./PosterForm"
import BuyButton from "../commerce/BuyButton"
import MeasureLine from "../print/MeasureLine"
import Button from "../ui/Button"

import { getLocationDataFromGoogle, getTextBottom } from "../utils/location"
import * as Icon from "react-feather"
import { track } from "../../utils/track"
import { baseSize, defaultState } from "../poster/data"
import { isLocationDifferentEnough } from "../map/utils"
import { parseLocation } from "../utils/location"
import usePoster from "../../hooks/usePoster"
import { getQueryString } from "../utils/url"
import { Switch } from "@material-ui/core"
import { useUITheme } from "../ui/theme"
import Logo from "../ui/assets/Logo"

const ReactHint = ReactHintFactory(React)

const Canvas = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`

const PosterWrap = styled.div`
  position: relative;
  width: ${p => p.width}px;
  height: ${p => p.height * p.scale}px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transition: all 0.3s ease-out;
`

const Controls = styled.div`
  padding: 20px;
  max-width: 520px;
  border-right: 1px solid ${p => p.theme.palette.divider};

  ${p =>
    !p.mobile
      ? `
  overflow: hidden;
  overflow-y: scroll;
  `
      : ""} height: 100vh;
  > img {
    margin-bottom: 20px;
  }
`

const Tips = styled.div`
  font-size: 1em;
  color: ${p => p.theme.palette.text.secondary};
  max-width: 500px;
  padding: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  span {
    margin-left: 5px;
  }
`

const BackBar = styled.div`
  display: flex;
  padding: 10px 0px;
  padding-top: 0px;
  cursor: pointer;
  align-items: center;
  color: ${p => p.theme.palette.text.secondary};
  justify-content: flex-start;
`

const Banana = styled.img`
  position: absolute;
  height: ${p => (p.size / 18) * 6}px;
  transition: all 1s;
  transform: rotate(${p => (p.visible ? 0 : -90)}deg);
  right: ${p => (p.visible ? `-75px` : `-55vw`)};
  z-index: 10000;
`

const EditorWrap = styled.div`
  width: 100%;
  height: 100vh;

  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch; /* lets it scroll lazy */

  display: flex;
  // flex-direction: column;
`

const ThemeSwitchContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  cursor: pointer;
  left: ${p => (p.isMobile ? 130 : 20)}px;
  top: 20px;
  z-index: 999;
  width: auto;
  color: ${p => p.theme.palette.text.secondary};
`

const Credits = styled.div`
  position: absolute;
  bottom: 10px;
  text-align: center;
  font-size: 14px;
  color: ${p => p.theme.palette.text.secondary};
`

const EditorTips = props => {
  const isTouch =
    typeof document !== `undefined` &&
    "ontouchstart" in document.documentElement
  return (
    <Tips>
      <Icon.Info size={18} />
      <span>
        {isTouch
          ? "Drag the map to adjust location. Pinch to zoom."
          : "Drag the map to adjust location. Scroll to zoom."}
      </span>
    </Tips>
  )
}

const Editor = props => {
  const [state, updatePoster] = usePoster()
  const [theme, setTheme] = useUITheme()
  const [toggles, setToggles] = useState({
    showControls: false,
    banana: false
  })

  const toggleState = key => () => {
    console.log("toggleState: ", key)
    const mutation = { [key]: !toggles[key] }
    track(`EditorToggle-${key}-${mutation[key]}`)
    console.log("toggleState: ", { ...toggles, ...mutation })
    setToggles({ ...toggles, ...mutation })
  }

  const canRecenter = () => {
    let { addressLocation, location } = state
    if (!location) location = parseLocation(state)
    return (
      location &&
      addressLocation &&
      isLocationDifferentEnough(location, addressLocation)
    )
  }

  const handleRecenter = () => {
    if (canRecenter()) {
      const { addressLocation } = state
      updatePoster({ location: addressLocation })
    }
  }

  const handleFormChange = data => {
    let location = { lat: state.lat, lng: state.lng }

    if (data.location !== undefined && data.location.location) {
      data = { ...data, ...getLocationDataFromGoogle(data) }
      location = data.location
    } else {
      delete data["location"]
    }

    if (data.theme && !isEqual(data.theme, state.theme)) {
      data.backgroundColor = data.theme[0]
      data.foregroundColor = data.theme[1]
      data.accentColor = null
      data.invert = false
    } else if (data.invert !== state.invert) {
      const bg = data.backgroundColor
      data.backgroundColor = data.foregroundColor
      data.foregroundColor = bg
    }

    if (data.syncCoords !== state.syncCoords) {
      if (data.syncCoords === true) {
        data.textBottom = getTextBottom(state.location)
      }
    }

    const nextState = { ...state, ...data, ...location }
    if (toggles.banana !== nextState.banana) {
      toggleState("banana")()
    }
    updatePoster(nextState)
  }

  const drawerWidth = () => {
    return Math.min(props.width, 450)
  }

  const mobileSize = () => {
    return props.width - drawerWidth() < 500 // poster width?
  }

  const renderControls = () => {
    const renderDrawerControls = () => (
      <Controls mobile={state.mobile}>
        <BackBar onClick={() => navigate(`/`)}>
          <Icon.ChevronLeft />
          <Logo width={30} />
          {/* <img
            alt="Tiltmaps logo"
            src={require("../../assets/logo.svg")}
            style={{
              maxWidth: 30,
              marginLeft: 10
            }}
          /> */}
        </BackBar>
        <PosterForm onChange={handleFormChange} data={state} />
      </Controls>
    )

    if (mobileSize()) {
      return (
        <Drawer
          width={drawerWidth()}
          open={toggles.showControls}
          docked={false}
          onClose={toggleState("showControls")}
        >
          {renderDrawerControls()}
        </Drawer>
      )
    } else {
      return renderDrawerControls()
    }
  }

  const handleMapChange = data => {
    if (state.syncCoords === true) {
      data.textBottom = getTextBottom(data.location)
    }
    updatePoster(data)
  }

  const { width } = props

  const size = baseSize
  const scaleDown = Math.min(width / size, 1)
  const height = state.size === "18x24" ? size * (24 / 18) : size

  const wrapBuffer = 80

  return (
    <EditorWrap className="Content">
      <ReactHint events delay={100} />
      {mobileSize() && !toggles.showControls ? (
        <Button
          label="Customize"
          onClick={toggleState("showControls")}
          foregroundColor={state.foregroundColor}
          backgroundColor={state.backgroundColor}
          style={{
            marginRight: 10,
            borderRadius: 20,
            position: "absolute",
            top: 20,
            left: 20,
            zIndex: 100
          }}
        />
      ) : null}

      {renderControls()}

      <Canvas>
        <ThemeSwitchContainer
          isMobile={mobileSize()}
          onClick={() => {
            const nextTheme = theme.name === "light" ? "dark" : "light"
            setTheme(nextTheme)
          }}
        >
          {theme.name === "light" ? <Icon.Moon /> : <Icon.Sun />}
          {/* <Switch
            checked={theme.name === "dark"}
            color="default"
            onChange={v => {
              const nextTheme = v.target.checked ? "dark" : "light"
              setTheme(nextTheme)
            }}
          /> */}
        </ThemeSwitchContainer>
        <BuyButton state={state} onCheckout={props.onCheckout} size={size} />

        <div style={{ position: "relative", paddingBottom: 40 }}>
          <EditorTips />
          {/* {canRecenter ? (
            <div
              style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                textAlign: "center"
              }}
            >
              <Button type="text" label="Recenter" onClick={handleRecenter}>
                <div
                  style={{
                    position: "relative",
                    height: "100%",
                    margin: "0 10px",
                    color: theme.palette.text.secondary,
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <Icon.Crosshair
                    size={18}
                    color={theme.palette.text.secondary}
                    style={{ marginRight: 5 }}
                  />
                  Recenter Location
                </div>
              </Button>
            </div>
          ) : null} */}
        </div>

        <PosterWrap
          width={size + wrapBuffer}
          height={height + wrapBuffer}
          scale={scaleDown}
        >
          <Banana
            visible={toggles.banana}
            size={size}
            framed={state.framed}
            src={require("../../assets/banana.png")}
          />
          <MeasureLine
            buffer={wrapBuffer}
            length={size}
            label={`18"`}
            style={{ left: 0, bottom: 0 }}
          />
          <Poster
            key={"poster"}
            scale={1}
            scaleDown={scaleDown}
            width={size}
            height={height}
            onChange={handleMapChange}
            animateMove={true}
            interactive={true}
            overlayImage={false}
            transitionIn={false}
            {...state}
          />
          <MeasureLine
            buffer={wrapBuffer}
            length={height}
            label={`${state.size.split("x")[1]}"`}
            style={{
              right: -(size + wrapBuffer),
              top: height + wrapBuffer,
              transform: "rotate(-90deg)",
              transformOrigin: 0
            }}
          />
        </PosterWrap>
        <Credits>
          Powered by <em>TiltMaps</em> &mdash; © TiltMaps, © Mapbox, ©
          OpenStreetMap
        </Credits>
      </Canvas>
    </EditorWrap>
  )
}

// const EditorContainer = props => {

//   if (!posterState) return null
//   return <Editor state={posterState} onChange={updatePosterData} {...props} />
// }

export default withSizes(sizes => sizes)(Editor)
