import React from "react"
import { ApolloProvider } from "@apollo/react-hooks"

import { MuiThemeProvider } from "@material-ui/core/styles"
import client from "./src/client"
import { PosterContextProvider } from "./src/hooks/usePoster"
import { CartContextProvider } from "./src/hooks/useCart"
import { ThemeProvider } from "styled-components"
import {
  themes,
  useUITheme,
  UIThemeContextProvider
} from "./src/components/ui/theme"

const ThemeAdaptorContextProvider = ({ children }) => {
  const [theme, setTheme] = useUITheme()
  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </MuiThemeProvider>
  )
}

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <UIThemeContextProvider>
      <PosterContextProvider>
        <ThemeAdaptorContextProvider>
          <CartContextProvider>{element}</CartContextProvider>
        </ThemeAdaptorContextProvider>
      </PosterContextProvider>
    </UIThemeContextProvider>
  </ApolloProvider>
)
